<template>
    <div>
      <q-form ref="editForm">
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-qr-btn
                v-if="editable && popupParam.heaEtrParticipantStatusId"
                :mobileUrl="'/hea/etr/satisfactionSurvey?heaEtrParticipantStatusId='+ popupParam.heaEtrParticipantStatusId"
              ></c-qr-btn>
              <c-btn
                v-show="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="partiData"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback" />
              <!-- <c-btn 
                v-show="requestBtnEditable" 
                :isSubmit="isRequest"
                :url="requestUrl"
                :param="partiData"
                mappingType="PUT"
                label="대상자에게 메일전송" 
                icon="check"
                @beforeAction="requestInfo"
                @btnCallback="requestCallback" /> -->
              <c-btn
                v-show="editable && isOld"
                label="LBLREMOVE"
                icon="remove"
                @btnClicked="removeInfo" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              <c-datepicker
                :required="true"
                :editable="editable"
                label="대상년도"
                type="year"
                default="today"
                name="participantYear"
                v-model="partiData.participantYear">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              <c-text
                :required="true"
                :editable="editable"
                suffix="회차"
                label="회차"
                name="participantRound"
                v-model="partiData.participantRound">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <c-text
                :required="true"
                :editable="editable"
                label="제목"
                name="participantTitle"
                v-model="partiData.participantTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              <c-plant
                :editable="editable"
                type="edit"
                label="사업장"
                name="plantCd"
                v-model="partiData.plantCd">
              </c-plant>
            </div>
          </template>
        </c-card>
      </q-form>
      <c-table
        ref="table"
        title="대상자 목록"
        class="q-mt-sm"
        :columns="grid.columns"
        :data="partiData.users"
        :gridHeight="gridHeight"
        :filtering="false"
        :isExcelDown="false"
        :isFullScreen="false"
        :columnSetting="false"
        :editable="editable"
        selection="multiple"
        rowKey="heaEtrParticipantStatusUserId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addUserData" />
            <c-btn v-if="editable" label="LBLEXCEPT" icon="delete" @btnClicked="deleteUserData" />
          </q-btn-group>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  <script>
  import { uid } from 'quasar';
  import selectConfig from '@/js/selectConfig';
  import transactionConfig from '@/js/transactionConfig';
  export default {
    name: 'participantStatusDetail',
    props: {
      popupParam: {
        type: Object,
        default: () => ({
          heaEtrParticipantStatusId: '',
        }),
      },
      contentHeight: {
        type: [String, Number],
        default: function() {
          return 'auto'
        },
      }
    },
    data() {
      return {
        partiData: {
          heaEtrParticipantStatusId: '',
          participantRound: '',
          participantYear: '',
          participantDate: '',
          participantTitle: '',
          regUserId: '',
          chgUserId: '',
          plantCd: null,
          requestFlag: 'N',
          users: [], 
          deleteUsers: [], 
        },
        grid: {
          columns: [
            {
              name: 'upDeptName',
              field: 'upDeptName',
              label: '반',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '팀',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '대상자',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'age',
              field: 'age',
              style: 'width: 60px',
              label: '연령',
              align: 'center',
              sortable: true,
              fix: true,
            },
            {
              name: 'longevityCount',
              field: 'longevityCount',
              label: '근속년수',
              align: 'left',
              style: 'width: 90px',
              sortable: false,
              fix: true,
            },
            { 
              name: 'overlappingArea',
              field: 'overlappingArea',
              label: '중복부위',
              align: 'left',
              type: 'text',
              style: 'width: 90px',
              sortable: false,
            },
            { 
              name: 'treatmentArea',
              field: 'treatmentArea',
              label: '치료부위',
              align: 'left',
              style: 'width: 250px',
              sortable: false,
            },
            { 
              name: 'participationCategory',
              field: 'participationCategory',
              label: '참여구분',
              type: 'select',
              codeGroupCd: 'PARTICIPANT_TYPE_CD',
              style: 'width: 100px',
              sortable: false,
            },
            { 
              range: true,
              name: 'treatmentPeriod',
              field: 'treatmentPeriod',
              label: '치료기간',
              type: 'date',
              style: 'width: 200px',
              sortable: false,
            },
            { 
              name: 'checklist6BeforeScore',
              field: 'checklist6BeforeScore',
              label: '통증점수',
              align: 'right',
              style: 'width: 90px',
              sortable: false,
            },
            { 
              name: 'finalScore',
              field: 'finalScore',
              label: '최종점수',
              align: 'right',
              style: 'width: 90px',
              sortable: false,
            },
            { 
              name: 'attendanceFlag',
              field: 'attendanceFlag',
              label: '출석여부',
              type: 'check',
              true: 'Y',
              false: 'N',
              align: 'center',
              style: 'width: 80px',
              sortable: false,
            },
            { 
              name: 'remark',
              field: 'remark',
              label: '비고',
              type: 'textarea',
              align: 'left',
              style: 'width: 150px',
              sortable: false,
            },
          ],
        },
        useFlagItems: [
          { code: 'Y', codeName: '사용' },
          { code: 'N', codeName: '미사용' },
        ],
        editable: true,
        isSave: false,
        detailUrl: '',
        saveUrl: '',
        deleteUrl: '',
        mappingType: 'PUT',
        popupOptions: {
          target: null,
          title: '',
          visible: false,
          width: '90%',
          top: '10px',
          param: {},
          closeCallback: null,
        },
        checkUrl: '',
        requestUrl: '',
        isRequest: false,
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    computed: {
      isOld() {
        return Boolean(this.popupParam.heaEtrParticipantStatusId);
      },
      gridHeight() {
        let height = this.contentHeight - 200;
        if (!height || height < 400) {
          height = 400
        }
        return String(height) + 'px';
      },
      requestBtnEditable() {
        return this.partiData.requestFlag !== 'Y'
      }
    },
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        // url setting
        this.detailUrl = selectConfig.hea.etr.participant.get.url
        this.checkUrl = selectConfig.hea.etr.participant.check.url;
        this.saveUrl = transactionConfig.hea.etr.participant.update.url
        this.deleteUrl = transactionConfig.hea.etr.participant.delete.url
        this.requestUrl = transactionConfig.hea.etr.participant.request.url
        // code setting
        // list setting
        this.getDetail();
      },
      getDetail() {
        if (this.isOld) {
          this.$http.url = this.$format(this.detailUrl, this.popupParam.heaEtrParticipantStatusId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.$_.extend(this.partiData, _result.data)
                  
            this.$_.forEach(this.partiData.users, _item => {
              if (_item.treatmentStartDate && _item.treatmentEndDate) {
                _item.treatmentPeriod = [_item.treatmentStartDate, _item.treatmentEndDate]
              }
            })
            // 상세팝업 최초작성/최근수정 정보 노출
            this.$emit('setRegInfo', _result.data)
          },);
        } else {
          // nothing...
        }
      },
      deleteUserData() {
        let selectData = this.$refs['table'].selected;
        if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (!this.partiData.deleteUsers) this.partiData.deleteUsers = [];
          this.$_.forEach(selectData, item => {
            if (item.editFlag !== 'C'
              && this.$_.findIndex(this.partiData.deleteUsers, { sopRestFacilitiesFixturesId: item.sopRestFacilitiesFixturesId }) === -1) {
                this.partiData.deleteUsers.push(item);
            }
            this.partiData.users = this.$_.reject(this.partiData.users, item);
          })
          this.$refs['table'].$refs['compo-table'].clearSelection();
        }
      },
      addUserData() {
        this.popupOptions.title = '근골격계질환 대상자 검색';
        this.popupOptions.param = {
          type: 'multiple',
        };
        this.popupOptions.target = () => import(`${'./participantStatusPop.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeTargetUserPopup;
      },
      closeTargetUserPopup(data) {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        if (data && data.length > 0) {
          this.$_.forEach(data, item => {
            if (this.$_.findIndex(this.partiData.users, { heaMuscleWorkerSurveyId: item.heaMuscleWorkerSurveyId }) === -1) {
              this.partiData.users.push({
                heaEtrParticipantStatusId: this.popupParam.heaEtrParticipantStatusId,
                heaEtrParticipantStatusUserId: uid(),
                userId: item.userId, // user id
                userName: item.userName, // 성명
                deptName: item.deptName, // 소속
                upDeptName: item.upDeptName, // 상위소속
                age: item.age, // 연령
                longevityCount: item.longevityCount, // 근속년수
                overlappingArea: '',
                treatmentArea: item.part, // 치료부위
                participationCategory: 'PT00000001', // 유소견자/요관찰
                treatmentStartDate: '',
                treatmentEndDate: '',
                checklist1: 'SSC0000001',
                checklist2: 'SSC0000002',
                checklist3: 'SSC0000003',
                checklist4: 'SSC0000004',
                checklist5: 'SSC0000005',
                checklist6: 'SSC0000006',
                checklist7: 'SSC0000007',
                checklist8: 'SSC0000008',
                checklist9: 'SSC0000009',
                checklist1Anw: 0,
                checklist2Anw: 0,
                checklist3Anw: 0,
                checklist4Anw: 0,
                checklist5Anw: 0,
                checklist6AcheArea: '', // 통증부위
                checklist6BeforeScore: item.painScore, // 치로전
                checklist6AfterScore: 0,  // 치료후
                checklist7Anw: 0,
                checklist8Anw: 0,
                checklist9Anw: '',
                attendanceFlag: 'N',
                finalScore: item.finalScore,
                heaMuscleWorkerSurveyId: item.heaMuscleWorkerSurveyId, // 근골격계 작업자 id
                regUserId: this.$store.getters.user.userId,  // 등록자
                editFlag: 'C',
              })
            }
          })
        }
      },
      saveInfo() {
        if (this.isOld) {
          this.saveUrl = transactionConfig.hea.etr.participant.update.url
          this.mappingType = 'PUT';
        } else {
          this.saveUrl = transactionConfig.hea.etr.participant.insert.url
          this.mappingType = 'POST';
        }
  
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            this.$http.url = this.checkUrl;
            this.$http.param = {
              participantYear: this.partiData.participantYear,
              participantRound: this.partiData.participantRound,
            }
            this.$http.type = 'GET';
            
          this.$http.request((_result) => {
            if (this.popupParam.heaEtrParticipantStatusId || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.partiData.regUserId = this.$store.getters.user.userId;
                  this.partiData.chgUserId = this.$store.getters.user.userId;
                  
                  this.$_.forEach(this.partiData.users, _item => {
                    if (_item.treatmentPeriod && _item.treatmentPeriod.length > 0) {
                      _item.treatmentStartDate = _item.treatmentPeriod[0];
                      _item.treatmentEndDate = _item.treatmentPeriod[1];
                    }
                  })
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 년도의 회차가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      },
      saveCallback(result) {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$set(this.popupParam, 'heaEtrParticipantStatusId', result.data)
        this.getDetail();
      },
      // requestInfo() {
      //   this.$refs['editForm'].validate().then(_result => {
      //     if (_result) {
      //       window.getApp.$emit('CONFIRM', {
      //         title: 'LBLCONFIRM',
      //         message: '운동치료실 대상자에게 메일 전송하여 설문조사를 요청하시겠습니까?',
      //         // TODO : 필요시 추가하세요.
      //         type: 'info', // success / info / warning / error
      //         // 확인 callback 함수
      //         confirmCallback: () => {
      //           this.isRequest = !this.isRequest
      //         },
      //         // 취소 callback 함수
      //         cancelCallback: () => {
      //         },
      //       });
      //     } else {
      //       window.getApp.$emit('APP_VALID_ERROR');
      //     }
      //   });
      // },
      // requestCallback() {
      //   window.getApp.$emit('APP_REQUEST_SUCCESS');
      //   this.getDetail();
      // },
      removeInfo() {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaEtrParticipantStatusId);
            this.$http.type = 'DELETE';
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$emit('closePopup')
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      },
    }
  };
  </script>